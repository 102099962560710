import { Button } from '@pattern-library/src/basicUI/Button'
import { Flex } from '@pattern-library/src/basicUI/Flex'
import { Gutter } from '@pattern-library/src/basicUI/Gutter'
import { Text } from '@pattern-library/src/basicUI/Text'
import { useSignout } from '@pattern-library/src/hooks/useSignout'
import { styled } from '@pattern-library/src/stitches.config'
import { useParams } from 'next/navigation'
import { FC } from 'react'
import { useGetAllAppContext } from 'src/hooks/useGetAllAppContext'

import { AppLogo } from '../../_components/AppLogo'

type UserWorkspaceErrorUIProps = {
  errorMessage?: string
}

export const UserWorkspaceErrorUI: FC<UserWorkspaceErrorUIProps> = ({ errorMessage = '' }) => {
  const { signOut } = useSignout()
  const { user } = useGetAllAppContext()
  const useParamas = useParams()
  const workspace = useParamas.workspace as string

  console.log('errorMessage', errorMessage)

  return (
    <BackgroundContainer>
      <Gutter>
        <Flex direction="column" gap={7}>
          <div>
            <AppLogo requestedHeight={60} />
          </div>
          {errorMessage.includes('Not Authorized') ? (
            <Text>
              User <HighlightedText>{user?.email}</HighlightedText> doesn't have the required permissions to access
              workspace <HighlightedText>{workspace}</HighlightedText>. Log out to sign in with a different user, or
              return to your workspaces.
              {/* To gain access, please contact your system administrator. */}
            </Text>
          ) : errorMessage.includes('Workspace not found') || errorMessage.includes('item does not exists') ? (
            <Text>
              The workspace titled <HighlightedText>{workspace}</HighlightedText> cannot be located. It is possible that
              a typographical error has occurred in the URL, or that the user{' '}
              <HighlightedText>{user?.email}</HighlightedText> lacks sufficient access permissions. Please navigate back
              to your list of workspaces or log out to sign in with an alternative user account.
            </Text>
          ) : (
            <Text>
              An error occurred while loading your workspace.
              <br />
              <b>Please check if you have the correct URL or the necessary permissions.</b>
              <br />
              If the problem persists, try again later or contact your system administrator.
            </Text>
          )}
          <Flex justify={'center'} gap={5}>
            <a href={`https://${process.env.NEXT_PUBLIC_APP_DOMAIN}`}>
              <Button label="My Workspaces" />
            </a>
            <Button label="Log Out" onClick={() => signOut()} />
          </Flex>
        </Flex>
      </Gutter>
    </BackgroundContainer>
  )
}

const BackgroundContainer = styled('div', {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: '$gs2',
  color: '$gs12',
})

const HighlightedText = styled('span', {
  color: '$war',
  backgroundColor: '$warText',
  padding: '$0 $1',
  fontWeight: 'bold',
})
