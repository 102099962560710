'use client'

import { Box } from '@pattern-library/src/basicUI/Box'
import { Divider } from '@pattern-library/src/basicUI/Divider'
import { Flex } from '@pattern-library/src/basicUI/Flex'
import { Skeleton } from '@pattern-library/src/basicUI/Skeleton'
import { Text } from '@pattern-library/src/basicUI/Text'
import { TextCube } from '@pattern-library/src/basicUI/TextCube'
import { Textfield } from '@pattern-library/src/basicUI/Textfield'
import { AuthenticatedCenteredTemplateWithData } from 'app/(authenticated)/[workspace]/[brand]/[siteId]/_components/AuthenticatedCenteredTemplateWithData'
import { UserWorkspaceErrorUI } from 'app/(authenticated)/_components/UserWorkspaceErrorUI'
import { useRouter } from 'next-nprogress-bar'
import Link from 'next/link'
import { useMemo, useState } from 'react'
import { useCreateSiteMutation, useGetWorkspaceByDomainWithUserQuery } from 'src/graphql/operations'
import { WorkspacePermissions } from 'src/hoc/withLoggedInUserPermissions'
import { useGetAllAppContext } from 'src/hooks/useGetAllAppContext'
import { getMaximalUserPermissionLevel, userHasPermission } from 'src/utils/permission-utils'
import { buildDomainForGraphQL, publicationSiteInputCreator } from 'src/utils/publication-utils'
import { authenticatedPaths } from 'src/utils/route-utils'
import { buildAuthenticatedRoute } from 'src/utils/url-utils'

const MINIMAL_AMOUNT_OF_PUBLICATIONS_BEFORE_SHOWING_SEARCH = 3

export default function Client({ workspace }: { workspace: string }) {
  const { user } = useGetAllAppContext()
  const router = useRouter()
  const [cubeIdLoading, setCubeIdLoading] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  const { loading, error, data } = useGetWorkspaceByDomainWithUserQuery({
    variables: {
      domain: buildDomainForGraphQL(workspace),
      username: user?.email as string,
    },
    skip: !user?.email,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    returnPartialData: true,
    // errorPolicy: "all",
  })

  const workspacePermissions: WorkspacePermissions['workspacePermissions'] =
    data?.getWorkspaceByDomain.getWorkspaceUser?.workspaceGroups

  const [onCreateSite] = useCreateSiteMutation({
    onError: error => {
      console.error('error creating visual identity site', error)
      alert('Error creating site. Please try again later or contact your system administrator')
      setCubeIdLoading('')
    },
    onCompleted: data => {
      console.log('Site has been created:', data.createSite)
      const brandPath = data.createSite.getBrand?.path
      const siteId = data.createSite.id
      if (!brandPath) {
        console.error("Could not create site because `brandPath` isn't defined")
        alert('Error creating site. Please try again later or contact your system administrator')
        setCubeIdLoading('')
      } else {
        router.push(buildAuthenticatedRoute({ workspace, brand: brandPath, siteId }))
        setCubeIdLoading('')
      }
    },
  })

  const userPermissionGroup = useMemo(
    () => getMaximalUserPermissionLevel({ workspacePermissions }),
    [workspacePermissions],
  )

  if (error) {
    return <UserWorkspaceErrorUI errorMessage={error.message} />
  }

  const workspaceHasPublications = data?.getWorkspaceByDomain?.listWorkspaceBrands?.items?.length
  if (!loading && !workspaceHasPublications && data !== undefined) {
    // User has no publications (brands), so we redirect to the new publication page
    router.push(buildAuthenticatedRoute({ workspace, pageSlug: authenticatedPaths.newPublication }))
  }

  const numberOfPublications = data?.getWorkspaceByDomain?.listWorkspaceBrands?.items?.length || 0
  const publications = [...(data?.getWorkspaceByDomain?.listWorkspaceBrands?.items || [])]
  const filteredPublications = (
    numberOfPublications > MINIMAL_AMOUNT_OF_PUBLICATIONS_BEFORE_SHOWING_SEARCH
      ? publications.filter(brand => {
          return brand.name.toLowerCase().includes(searchTerm.toLowerCase())
        })
      : publications
  )?.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })

  return (
    <AuthenticatedCenteredTemplateWithData>
      <Box css={{ mb: '$10' }}>
        <Text variant="h3" css={{ color: '$gs12' }} oll={true}>
          Publications
        </Text>
      </Box>

      {loading && !data?.getWorkspaceByDomain?.listWorkspaceBrands?.items?.length ? (
        <>
          <Skeleton height="h3" verticalSpacing="medium" />
          <Skeleton height="h3" verticalSpacing="medium" />
          <Skeleton height="h3" verticalSpacing="medium" />
          <Skeleton height="h3" verticalSpacing="medium" />
        </>
      ) : (
        <>
          {numberOfPublications > MINIMAL_AMOUNT_OF_PUBLICATIONS_BEFORE_SHOWING_SEARCH && (
            <Textfield
              placeholder={'Search for a publication'}
              css={{
                mb: '$5',
                width: 'min(100%, 400px)',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              value={searchTerm}
              name="search"
              onChange={e => setSearchTerm(e.target.value)}
              autoFocus={true}
            />
          )}
          <Flex
            gap={8}
            direction="column"
            css={{
              p: '$5',
              pr: '$10',
            }}>
            {filteredPublications?.map(el => (
              <TextCube
                state={cubeIdLoading === el.id ? 'loading' : 'default'}
                title={el.name}
                key={el.id}
                onClick={() => {
                  const brandPath = el.path
                  const siteId = el.listBrandSites?.items[0]?.id
                  if (siteId) {
                    setCubeIdLoading(el.id)
                    router.push(buildAuthenticatedRoute({ workspace, brand: brandPath, siteId }))
                  } else {
                    setCubeIdLoading(el.id)
                    onCreateSite({
                      variables: {
                        site: {
                          ...publicationSiteInputCreator({ brandId: el.id }),
                          name: el.name,
                        },
                      },
                    })
                  }
                }}
              />
            ))}
            {numberOfPublications > MINIMAL_AMOUNT_OF_PUBLICATIONS_BEFORE_SHOWING_SEARCH &&
              filteredPublications?.length === 0 && <Text>No publication found.</Text>}
          </Flex>
        </>
      )}
      {userHasPermission(userPermissionGroup, 'Admin') && (
        <>
          <Divider
            css={{
              mt: '$8',
              mb: '$5',
            }}
          />
          <Link
            href={buildAuthenticatedRoute({
              workspace,
              pageSlug: authenticatedPaths.newPublication,
            })}>
            <Text isLink={true} css={{ color: '$lin' }}>
              New Publication
            </Text>
          </Link>
        </>
      )}
    </AuthenticatedCenteredTemplateWithData>
  )
}
