import { DropdownExposedProps } from 'pattern-library/src/basicUI/Dropdowns/DropdownExposed'

import { BrandGroup } from '@models/types'

import { WorkspaceGroup } from '../graphql/operations'
import { BrandPermissions, WorkspacePermissions } from '../hoc/withLoggedInUserPermissions'

export const DEFAULT_BRAND_PERMISSION_GROUP: BrandGroup = 'Builder'
export const DEFAULT_WORKSPACE_PERMISSION_GROUP: WorkspaceGroup = 'Builder'

export const workspaceUserGroups: Record<string, WorkspaceGroup> = {
  admin: 'Admin',
  builder: 'Builder',
  creator: 'Creator',
  guest: 'Guest',
  accounting: 'Accounting',
}

export const brandUserGroups: Record<string, BrandGroup> = {
  builder: 'Builder',
  creator: 'Creator',
  guest: 'Guest',
}

export const permissionLevelNameForFrontend = {
  Admin: 'Administrator',
  Accounting: 'Accounting',
  Builder: 'Editor',
  Creator: 'Writer',
  Guest: 'Guest',
  None: 'None',
}

export const brandPermissionLevelsForDropdownExposed: { key: BrandGroup; label: string }[] &
  DropdownExposedProps['items'] = [
  { key: 'Builder', label: permissionLevelNameForFrontend.Builder },
  { key: 'Creator', label: permissionLevelNameForFrontend.Creator },
  { key: 'Guest', label: permissionLevelNameForFrontend.Guest },
]

export type workspacePermissionLevelsForDropdownExposedType = { key: WorkspaceGroup; label: string }[] &
  DropdownExposedProps['items']
export const workspacePermissionLevelsForDropdownExposed: workspacePermissionLevelsForDropdownExposedType = [
  { key: 'Accounting', label: permissionLevelNameForFrontend.Accounting },
  { key: 'Admin', label: permissionLevelNameForFrontend.Admin },
  { key: 'Builder', label: permissionLevelNameForFrontend.Builder },
  { key: 'Creator', label: permissionLevelNameForFrontend.Creator },
  { key: 'Guest', label: permissionLevelNameForFrontend.Guest },
  { key: 'None', label: permissionLevelNameForFrontend.None },
]

const possibleLevelsAsHighestToLowest: (WorkspaceGroup | BrandGroup)[] = [
  'Admin',
  'Accounting',
  'Builder',
  'Creator',
  'Guest',
  'None',
]

const possibleLevelsAsLowestToHighest = possibleLevelsAsHighestToLowest.slice().reverse()

export const getMaximalUserPermissionLevel = ({
  workspacePermissions,
  brandPermissions,
}: {
  workspacePermissions?: WorkspacePermissions['workspacePermissions']
  brandPermissions?: BrandPermissions['brandPermissions']
}): WorkspaceGroup | BrandGroup => {
  for (const level of possibleLevelsAsHighestToLowest) {
    if (workspacePermissions?.find(group => group === level) || brandPermissions?.find(group => group === level)) {
      return level
    }
  }
  return 'Guest'
}

export const userHasPermission = (
  userPermissionGroup: WorkspaceGroup | BrandGroup,
  requiredPermissionGroup: WorkspaceGroup | BrandGroup,
) => {
  const userPermissionGroupIndex = possibleLevelsAsLowestToHighest.indexOf(userPermissionGroup)
  const requiredPermissionGroupIndex = possibleLevelsAsLowestToHighest.indexOf(requiredPermissionGroup)
  return userPermissionGroupIndex >= requiredPermissionGroupIndex
}
